'use strict'
const servicesList = document.querySelector('.services__list');
const servicesItems = servicesList.querySelectorAll('.services__item');
const servicesButtonBack = document.querySelector('.services__button--back');
const servicesButtonNext = document.querySelector('.services__button--next');
const submenuLinks = document.querySelectorAll('.site-navigation__submenu-link');

let currentIndex = 0;

servicesButtonNext.addEventListener('click', () => {
  currentIndex++;
  updateServisecList(currentIndex);
})

servicesButtonBack.addEventListener('click', () => {
  currentIndex--;
  updateServisecList(currentIndex);
})

submenuLinks.forEach(element => {
  element.addEventListener('click', (evt) => {
    currentIndex = Number(element.id);
    updateServisecList(currentIndex);
  })
});

function updateServisecList(index) {
  servicesButtonNext.disabled = false;
  servicesButtonBack.disabled = false;

  servicesList.style.transform = `translateX(${-index * 100}%)`;

  if (index === servicesItems.length - 1) {
    servicesButtonNext.disabled = true;
  }

  if (index === 0) {
    servicesButtonBack.disabled = true;
  }
}
